.grid-container-vertical {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2vh;
    padding: 2vh 10px 10px;
    overflow: visible !important;
}
:root{
    --vHeight: 100%;
    --vWidth: 100%;
    --modalWidth: 70%;
    --modalHeight: 100%;
}
.grid-item {
    grid-row: auto;
    grid-column: auto;
    display: block;
    margin: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: var(--color-light-alt);
    transition: 1s;
    border-radius: 5px;
    max-width: 900px;
    width: 100%;
    height: 100%;
    padding: 0 5px;
}
.item-sold {
    background-color: var(--color-dark);
    color: white;
    opacity: 50%;
}
.grid-item:hover{
    background: var(--color-dark);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-transition: -webkit-transform 0.25s;
    -moz-transition: -moz-transform 0.25s;
    -ms-transition:  -ms-transform 0.25s;
    transition:  transform 0.25s;
    opacity: 100%;
    color: white;
}

.item-image{
    width: 18vw;
    object-fit: cover;
    margin: 10px;
    max-width: 300px;
}
.grid-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    display: flex;
    allign-items: center;
    justify-content: center;
}
.grid-modal-content {
    height: 95vh;
    background-color: var(--color-light);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Center children vertically */
    align-items: center; /* Center children horizontally */
    margin: auto;
    width: var(--modalWidth);
    padding-inline: 2%;
    max-height: 60vw;
}
.grid-modal-header{
    height: 5%;
    margin-top: 1em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.grid-modal-body{
    top: 0;
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}
.grid-modal-body-left{
    height: 100%;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
    flex-direction: column;
}
.grid-modal-body-right{
    height: 80vh;
    width: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    /*margin-left: 50px;*/
    font-size: 1.2rem;
}
.grid-modal-right-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 93%;
    margin: 10px;
}
.whitespace{
    height: 50px;
}
.grid-modal-footer{
    display: none;
}
.grid-modal-button{
    background: transparent;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 50px;
    cursor: pointer;
    transition: 1s;
}
.mode-button{
    color: var(--color-dark-alt);
    border: none;
    border-radius: 8px;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    transition: 1s;
}
.mode-button:hover{
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-transition: -webkit-transform 0.25s;
    -moz-transition: -moz-transform 0.25s;
    -ms-transition:  -ms-transform 0.25s;
    transition:  transform 0.25s;
}
.title-text{
    margin: 0;
}
.content-title{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    font-size: 2rem;
    border-bottom: 1px solid var(--color-dark-alt);
    position: sticky;
}
.contact-title{
    margin-bottom: 2vh;
}
.item-image{
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.owned-true{
    background: var(--color-light-alt);
}
.mode-button-mobile{
    font-size: 3vh;
}
.active-mode{
    color: black;
}
.grid-modal-button:hover{

}
.grid-modal-button:active{

}
.modal-button-info-switch{
    background: var(--color-dark-alt);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    transition: 1s;
}
.grid-modal-right-content-body{
    width: 100% !important;
    overflow-y: scroll;
    max-height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 10%;
}
.grid-modal-right-content-body::-webkit-scrollbar {
    display: none;
}
.inline{
    width: 100% !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-dark-alt);
    text-align: justify;
}
.content{
    top: 10px;
}
.hidden-mobile{
    display: flex;
}
.helpertext{
    text-wrap: nowrap;
    position: relative;
    margin: 2vh
}

@media only screen and (min-width: 2500px) {
    .grid-container-vertical {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}


@media only screen and (min-width: 4000px) {
    .grid-container-vertical {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 1200px) {
    .grid-container-vertical {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin: 5% 10%;
    }
}



@media only screen and (max-width: 1050px) {
    .grid-container-vertical {
        grid-template-columns: 1fr 1fr 1fr;
        margin: 5% 5%;
    }
    .item-image{
        width: 20vw;
    }
    .grid-modal-content {
        max-height: 100vh;
        width: 100vw;
        height: 100vh;
        margin: 0;
        justify-content: start;

    }
    .nav-button{
        display: none;
    }
    .grid-item{
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
    .grid-modal-body{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 85%;
    }
    .grid-modal-body-left{
        width: 100%;
        height: 100%;
        margin-right: 0;
        margin-top: 10vh;
    }
    .grid-modal-header{
        width: 100%;
        height: 5%;
        margin-top: 0;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        z-index: 100;
        background: var(--color-light);
        opacity: 70%;
    }
    .grid-modal-button{
        font-size: 30px;
    }
    .grid-modal-body-right{
        width: 80%;
        margin-top: 10vh;
    }
    .helpertext{
        margin: 0;
        position: absolute;
        top: 5vh;
    }
    .grid-modal-footer{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height: 10%;
        margin-bottom: 0;
        position: absolute;
        bottom: 0;
        background: transparent;
        opacity: 70%;
    }
    .page{
        max-height: 90vh !important;
    }


    .modal-button-info-switch{
        max-width: 25%;
    }
    .grid-modal-button:hover{

    }
    .grid-modal-button:active{

    }
    .hide-mobile{
        display: none;
    }


}

@media only screen and (max-width: 900px) {
    .grid-container-vertical {
        grid-template-columns: 1fr 1fr;
        margin: 0 auto;
    }


}
