.scroll-arrow{
    position: fixed;
    bottom: 3vh;
    right: 5vw;
    cursor: pointer;
    transition: 1.8s;
}

.scroll-arrow:hover{

}

.arrow{
    height: 10vh;
    border-radius: 100%;
    transition: 5s;
}

.arrow:hover{
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-transition: -webkit-transform 0.25s;
    -moz-transition: -moz-transform 0.25s;
    -ms-transition:  -ms-transform 0.25s;
    transition:  transform 0.25s;
}

.up{
    transition: 1s;
    transform: rotate(180deg);
}