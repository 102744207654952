
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.head {
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 50px;
    width: 50%;
}

.title_main{
    justify-content: center;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    color: var(--color-dark);
}

.title_sub{
    padding: 0 0 0 0;
    margin: 0 0 25px 0;
    color: #2c2d2d;
    font-size: x-large;
}

.title{
    max-width: 550px;
    min-width: 350px;
    width: 50%;
    padding: 0 0 0 70px;
}
.chapter{
    background: var(--color-light-alt);
    margin: 20px auto auto auto;
    border: 1px solid var(--color-dark-alt);
    border-radius: 30px;
    width: 50%;
    max-width: 550px;
    min-width: 350px;
    cursor: pointer;
}

.chapter :hover{
    color: var(--color-dark-alt);
}

.title_chap{
    padding: 0 0 0 35px;
}

.references {
    padding: 0 35px 0 35px;
    display: block;
    transition: all 2s linear;
}

.reference a{
    color: #2c2d2d;
}

.hidden{
    display: none;
    opacity: 0;
}

.white_space{
    margin: 4rem;
}

@media only screen and (max-width: 1000px) {
    .title{
        margin: 0 0 0 0;
    }
}
.scroll-arrow{
    width: 25%;
}