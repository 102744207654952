.contact-form {
    padding: 0px;
    border-radius: 5px;
    margin: auto 1vw auto auto;
}

.contact-form input[type="text"],
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--color-light-alt);
    border-radius: 3px;
}
.contact-form input[type="text"]:focus,
.contact-form textarea:focus {
    outline: none;
    border-color: var(--color-dark-alt);
}

.contact-form textarea {
    height: 100px;
}

.contact-form button {
    background-color: var(--color-dark-alt);
    color: white;
    border: none;
    border-radius: 3px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-form button:hover {
    background-color: var(--color-dark);
}

.contact-form span {
    display: block;
    margin-top: 10px;
}
